<template>
  <div id="app">
    <router-view :key="$router.currentRoute.fullPath" />
    <LoginByEmail v-if="loginEmailDialogShow" />
    <PayDialog v-if="payDialogShow" />
    <FooterComponent />
  </div>
</template>

<script>
import PayDialog from "./components/Pay.vue";
import FooterComponent from "./components/Footer";
import LoginByEmail from "./components/LoginByEmail.vue";
export default {
  data() {
    return {};
  },
  components: {
    PayDialog,
    LoginByEmail,
    FooterComponent,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_base.scss";
#app {
  height: 100%;
  background-color: #f4f4f4;
}
</style>
