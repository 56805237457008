<template>
  <van-dialog class="login-dialog" v-model="show" :show-confirm-button="false">
    <div class="content">
      <div class="panel login-panel">
        <h3>登录/注册</h3>
        <p class="login-tip">如果您之前尚未注册，登录成功即会帮您注册账户</p>
        <el-input
          placeholder="请填写真实邮箱地址"
          v-model.trim="email"
          @keyup.enter.native="register"
        >
        </el-input>
        <el-input
          class="dark"
          placeholder="邮箱验证码"
          v-model.trim="emailcode"
          @keyup.enter.native="register"
        >
          <template slot="append">
            <van-button
              type="primary"
              :disabled="leftSecond > 0"
              @click="handleSendCode"
            >
              {{ leftSecond > 0 ? `${leftSecond}秒后重新获取` : "获取验证码" }}
            </van-button>
          </template>
        </el-input>
        <van-button
          type="primary"
          size="normal"
          block
          class="login-button"
          @click="login"
          :loading="loading"
          :disabled="!emailcode || !email"
          >登录</van-button
        >
        <p class="tip">
          登录成功即表示您同意本站用户协议
          <a href="/#/main/about/agree" target="_blank">《用户协议》</a>
        </p>
      </div>
      <van-icon class="close" name="cross" @click="hideLoginDialog" />
    </div>
  </van-dialog>
</template>

<script>
import { loginByEmail, sendEmailCode } from "@/api/loginByEmail.js";
import { isValidEmail } from "@/utils/validate.js";

export default {
  data() {
    return {
      show: true,
      email: "",
      emailcode: "",
      loading: false,
      timer: null,
      leftSecond: 0,
    };
  },
  destroy() {
    this.leftSecond = 0;
    clearInterval(this.timer);
  },
  methods: {
    async login() {
      if (!this.email || !this.emailcode) {
        return this.$toast.fail("请填写邮箱、密码和验证码");
      }
      const res = await loginByEmail({
        email: this.email,
        emailcode: this.emailcode,
      });
      if (res.code == 0) {
        this.$toast.success("登录成功");
        localStorage.setItem("token", res.data.token);
        location.reload();
      } else {
        return this.$toast.fail(res.message);
      }
    },
    handleCalLeftSecond() {
      this.leftSecond = 60;
      this.timer = setInterval(() => {
        if (this.leftSecond === 1) {
          clearInterval(this.timer);
        }
        this.leftSecond -= 1;
      }, 1000);
    },
    async handleSendCode() {
      if (!isValidEmail(this.email)) {
        return this.$toast.fail("请输入正确的邮箱地址");
      }
      sendEmailCode({ email: this.email }).then((res) => {
        if (res.code === 0) {
          this.handleCalLeftSecond();
          if (res.message) this.$toast.success(res.message);
        } else {
          return this.$toast.fail(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.login-dialog {
  width: 400px;
  top: 40%;
}
.content {
  .van-button {
    border-radius: 6px;
    &.login-button {
      height: 60px;
    }
  }
  padding: 5px 20px 40px;
  position: relative;
  .panel {
    padding-top: 15px;
    .el-input {
      margin-bottom: 15px;
    }
    .tip {
      font-size: 13px;
      margin-top: 10px;
      color: #333;
      text-align: center;
    }
    h3 {
      font-size: 20px;
      line-height: 1.5;
      padding-left: 10px;
      border-left: 6px solid $theme-color;
    }
    .login-tip {
      margin-top: 15px;
      margin-bottom: 25px;
      font-size: 13px;
      color: #333;
    }
  }
  .close {
    position: absolute;
    height: 36px;
    width: 36px;
    top: 10px;
    right: 15px;
    font-size: 18px;
    border-radius: 8px;
    line-height: 36px;
    color: #33383f;
    text-align: center;
    background-color: #efefef;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
}

@media (max-width: 700px) {
  .login-dialog {
    width: 90%;
    top: 45%;
    .content {
      padding: 5px 20px 20px;
    }
  }
}
</style>
