import { request } from '@/api/_base'

export const sendEmailCode = async ({ email }) => {
  return await request({
    method: 'POST',
    url: `/api/v1/send_email`,
    data: {
      email,
    }
  })
}

export const loginByEmail = async ({ email, emailcode }) => {
  return await request({
    method: 'POST',
    url: `/api/v1/login_by_email`,
    data: {
      email, emailcode
    }
  })
}

export const registerByEmail = async ({ email, emailcode }) => {
  return await request({
    method: 'POST',
    url: `/api/v1/register_by_email`,
    data: {
      email, emailcode
    }
  })
}
