export const FILE_TYPE_VIDEO = "filetype_video";
export const FILE_TYPE_AUDIO = "filetype_audio";
export const FILE_TYPE_PPT = "filetype_ppt";
export const FILE_TYPE_XLS = "filetype_xls";
export const FILE_TYPE_DOC = "filetype_doc";
export const FILE_TYPE_PDF = "filetype_pdf";
export const FILE_TYPE_ZIP = "filetype_zip";
export const FILE_TYPE_IMG = "filetype_img";
export const FILE_TYPE_OTHERS = "filetype_others";
export const FILE_TYPE_UNKNOWN = "filetype_unknown";
export const FILE_TYPE_FOLDER = "filetype_folder";

export const PROJECT_NAME = "个人互联网检索服务";
export const DOMAIN = window.location.host;

export const RESOURCE_FROM = {
  sopan_spider: {
    name: "52搜盘",
    url: "http://www.52sopan.com",
  },
  panduoduo: {
    name: "盘多多",
    url: "http://www.panduoduo.net",
  },
  "56wangpan": {
    name: "56网盘",
    url: "http://www.56wanpan.com",
  },
  pansoso: {
    name: "盘搜搜",
    url: "http://www.pansoso.com",
  },
  xiaobaipan: {
    name: "小白盘",
    url: "http://xiaobaipan.com",
  },
  quzhuanpan: {
    name: "去转盘",
    url: "https://www.quzhuanpan.com",
  },
};

export const FILTER = {
  type: {
    "-1": {
      name: "全部类型",
      value: "",
    },
    7: {
      name: "BT种子",
      value: 7,
    },
    6: {
      name: "压缩包",
      value: 6,
    },
    5: {
      name: "软件",
      value: 5,
    },
    4: {
      name: "文档",
      value: 4,
    },
    3: {
      name: "图片",
      value: 3,
    },
    2: {
      name: "音乐",
      value: 2,
    },
    1: {
      name: "视频",
      value: 1,
    },
    0: {
      name: "文件夹",
      value: 0,
    },
  },
  time: {
    "-1": {
      name: "全部时间",
      value: "",
    },
    3: {
      name: "最近一年",
      value: 365,
    },
    2: {
      name: "最近半年",
      value: 180,
    },
    1: {
      name: "最近一月",
      value: 30,
    },
    0: {
      name: "最近一周",
      value: 7,
    },
  },
  size: {
    "-1": {
      name: "全部大小",
      value: "",
    },
    3: {
      name: "大于2GB",
      minSize: 2147483648,
    },
    2: {
      name: "200MB-2GB",
      maxSize: 2147483648,
      minSize: 209715200,
    },
    1: {
      name: "20MB-200MB",
      maxSize: 209715200,
      minSize: 20971520,
    },
    0: {
      name: "小于20MB",
      maxSize: 20971520,
    },
  },
  searchtype: {
    1: {
      name: "模糊搜索",
      value: "match",
    },
    0: {
      name: "精确搜索",
      value: "precise",
    },
  },
  restype: {
    "-1": {
      name: "全部来源",
      value: "",
    },
    1: {
      name: "百度网盘",
      value: "baidu",
    },
    0: {
      name: "阿里网盘",
      value: "aliyundrive",
    },
    2: {
      name: "夸克网盘",
      value: "quark",
    },
  },
};
