import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    userinfo: null,
    filter: {
      show: false,
      time: -1,
      type: -1,
      size: -1,
      restype: 1,
      searchtype: -1,
    },
    paypackages: [],
    payDialogShow: false,
    loginDialogShow: false,
    loginEmailDialogShow: false,
    bindUserDialog: false,
  },
  mutations: {
    setLogin(state, data) {
      state.isLogin = data;
    },
    setUserinfo(state, data) {
      state.userinfo = data;
    },
    changeFilter(state, filter) {
      state.filter = filter;
    },
    showFilter(state) {
      state.filter.show = true;
    },
    hideFilter(state) {
      state.filter.show = false;
    },
    changeFilterSize(state, value) {
      if (value === undefined || value === null) {
        return;
      }
      state.filter.size = value;
    },
    changeFilterTime(state, value) {
      if (value === undefined || value === null) {
        return;
      }
      state.filter.time = value;
    },
    changeFilterType(state, value) {
      if (value === undefined || value === null) {
        return;
      }
      state.filter.type = value;
    },
    changeFilterSearchType(state, value) {
      if (value === undefined || value === null) {
        return;
      }
      state.filter.searchtype = value;
    },
    changeFilterResType(state, value) {
      if (value === undefined || value === null) {
        return;
      }
      state.filter.restype = value;
    },
    setPayPackages(state, data) {
      state.paypackages = data;
    },
    setPayDialog(state, data) {
      state.payDialogShow = data;
    },
    setLoginDialog(state, data) {
      state.loginDialogShow = data;
    },
    setLoginEmailDialog(state, data) {
      state.loginEmailDialogShow = data;
    },
    setBindUserDialog(state, data) {
      state.bindUserDialog = data;
    },
  },
  actions: {},
  modules: {},
});
