import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
import store from "../store/index";
import { getUserinfo } from "../api/user";

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Index.vue"),
  },
  {
    path: "/",
    redirect: "/main/search",
  },
  {
    path: "/main",
    name: "main",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "search",
        name: "search",
        component: () => import("@/views/Search.vue"),
        meta: {
          requiredAuth: true,
          requiredVip: true,
        },
      },
      {
        path: "notVip",
        name: "notVip",
        component: () => import("@/views/NotVip.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "copyright",
        name: "copyright",
        component: () => import("@/views/Copyright.vue"),
      },
      {
        path: "about/agree",
        name: "agree",
        component: () => import("@/views/about/agree.vue"),
      },
      {
        path: "about/policy",
        name: "policy",
        component: () => import("@/views/about/policy.vue"),
      },
      {
        path: "about/safe",
        name: "safe",
        component: () => import("@/views/about/safe.vue"),
      },
      {
        path: "about/version",
        name: "version",
        component: () => import("@/views/about/version.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

const checkIfLogin = async () => {
  if (store.state.userinfo)
    return { isLogin: true, isVip: !store.state.userinfo.expired };
  try {
    const res = await getUserinfo();
    if (res && res.code == 0) {
      store.commit("setLogin", true);
      store.commit("setUserinfo", res.data);
      return { isLogin: true, isVip: !res.data.expired };
    } else if (res && res.code == 1001) {
      store.commit("setLogin", false);
      localStorage.setItem("token", "");
      return { isLogin: false };
    }
    return { isLogin: false };
  } catch (err) {
    return { isLogin: false };
  }
};

router.beforeEach((to, from, next) => {
  // 页面兼容
  if (location.pathname != "/")
    location.href = "/#/main" + location.pathname + location.search;

  if (window._hmt) {
    // pv 打点
    if (to.path) {
      window._hmt.push([window.location.pathname + "#" + to.fullPath]);
    }
  }

  checkIfLogin().then(({ isLogin, isVip }) => {
    if (to.meta.requiredAuth) {
      if (!isLogin) {
        return next({ name: "login" });
      }

      if (!isVip && to.meta.requiredVip) {
        return next({ name: "notVip" });
      }

      if (isVip && to.name == "notVip") {
        return next({ name: "search" });
      }

      if (to.name == "search") {
        const { filter } = store.state;
        const query = to.query;
        if (
          filter.time !== -1 ||
          filter.size !== -1 ||
          filter.type !== -1 ||
          filter.searchtype !== -1 ||
          filter.restype !== -1
        ) {
          if (
            query.size !== undefined ||
            query.time !== undefined ||
            query.type !== undefined ||
            query.searchtype !== undefined ||
            query.restype !== undefined
          ) {
            next();
          } else {
            if (filter.size !== -1) {
              query.size = filter.size;
            }
            if (filter.time !== -1) {
              query.time = filter.time;
            }
            if (filter.type !== -1) {
              query.type = filter.type;
            }
            if (filter.searchtype !== -1) {
              query.searchtype = filter.searchtype;
            }
            if (filter.restype !== -1) {
              query.restype = filter.restype;
            }
            next({ path: "/main/search", query });
          }
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  });
});
export default router;
